@import "mixins";

TABLE {
  border-collapse: collapse; border-spacing: 0; @include default-margin; line-height: 24px;
  margin-left: auto; margin-right: auto; width: 100%;

  TR { page-break-inside: avoid; }

  TH, TD {
    border: solid black; border-width: 0 0 1px 0; padding: 8px 0 8px 20px; text-align: left; vertical-align: top;

    &:first-child { padding-left: 0; }
  }

  TH {
    font-weight: var(--medium); font-size: 18px; line-height: inherit;
    border-color: var(--color-neutral-700);
  }

  TD {
    border-color: var(--color-neutral-300);
  }

  THEAD:not(:first-child) {
    TR.separated TD,
    TR.separated TH { padding-top: var(--gridunit); }
  }

  TR.total TD { font-weight: 500; border-color: currentColor; }

  A {
    text-decoration: none; color: var(--color-link);

    &:hover { color: var(--color-link-hover); }
  }

  &.wrappable {
    @include mobile {
      white-space: normal;

      THEAD { display: none; }

      TR { display: flex; flex-direction: column; border-bottom: 1px solid var(--color-neutral-300); padding: 8px 0; }

      TD {
        display: block; order: 1; border: none; padding: 0 !important;

        &[data-label] {
          order: -1; font-size: 12px; line-height: normal; text-align: left !important;

          &:before {
            content: attr(data-label) " ";
          }
        }
      }
    }
  }

  &.persons {
    margin-top: var(--gridunit);

    TD { vertical-align: middle; white-space: wrap; }

    TH { vertical-align: top; white-space: wrap; }

    .primary {
      display: inline-flex; flex-direction: row; flex-wrap: nowrap; align-items: center; gap: var(--gridunit-small);

      &__image,
      &__icon {
        width: 48px; height: 48px; flex: 0 0 48px; background-color: var(--color-primary-100); border-radius: var(--radius-default); margin: 0; position: relative; overflow: hidden !important;

        IMG {
          object-fit: cover; object-position: 50% 0; border-radius: var(--radius-default); overflow: hidden;
        }
      }

      &__holder { position: relative; flex: 0 0 48px; z-index: 0; }

      &__image {
        object-fit: cover; object-position: 50% 0; border-radius: var(--radius-default); overflow: hidden;
      }

      &__icon {
        display: flex; align-items: center; justify-content: center; position: relative; overflow: visible;
      }

      &__status {
        width: 16px; height: 16px; border-radius: 50%; background-color: var(--color-neutral-400); pointer-events: none;
        position: absolute; right: -4px; top: -4px; z-index: 2;

        &.status-danger { background-color: var(--color-danger); }

        &.status-warning { background-color: var(--color-warning); }

        &.status-success { background-color: var(--color-success); }
      }
    }

    @include mobile {
      white-space: normal; margin: 0;

      THEAD { display: none; }

      TR { display: block; }

      TD {
        display: block; border: none; padding-top: var(--gridunit);

        &[data-label] {
          text-align: left !important; border-bottom: 1px solid var(--color-neutral-200); padding: 8px 0 !important;

          &:before {
            content: attr(data-label) " "; display: block; font-size: 12px; line-height: normal;
          }
        }
      }

      .primary {
        display: inline-block; font-size: 20px; font-weight: 500; text-decoration: underline;

        &__holder { flex: 0 0 auto; display: inline; position: static; }

        &__image { width: 144px; height: 144px; }

        &__icon {
          all: unset; position: relative; width: 16px; float: left; margin-right: var(--gridunit-tiny);

          svg { display: none; }
        }

        &__status { position: static; margin-top: 4px; float: left; margin-right: var(--gridunit-tiny); }

        &__image + * { margin-top: var(--gridunit-tiny); }
      }
    }
  }
}

.widget-title { margin-top: var(--gridunit); font-size: 18px; }

P:not([class]):has(> IMG) {
  display: flex;

  > IMG { width: 100%; height: auto; }
}
