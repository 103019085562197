@import 'mixins';

A { text-decoration: underline; }

A, BUTTON {
	color: currentColor;
}

HTML { -ms-overflow-style: scrollbar; overflow: auto; overflow-x: hidden; overflow-y: scroll; font-size: $html-font-size; }

BODY, HTML { position: relative; z-index: 0; box-sizing: border-box; height: 100%; }

@include not-mobile {
	BODY, HTML { height: 100vh; min-height: -webkit-fill-available; }

	#__next { min-height: 100vh; min-height: -webkit-fill-available; }
}

@media print {
	BODY, HTML { background: transparent; padding: 0; margin: 0; }

	* { -webkit-print-color-adjust: exact; box-shadow: none !important; }
}

OL:not([class]) {
	counter-reset: li; padding: var(--gridunit) 0 0 0; margin: 0; list-style: none;

	LI {
		display: block; padding: 0 0 0 calc(var(--gridunit) * 2.5); margin: calc(var(--gridunit) * 0.5) 0 0 0; position: relative; page-break-inside: avoid;

		&:before { content: counter(li) "."; counter-increment: li; color: currentColor; position: absolute; left: 0; top: 0; width: calc(var(--gridunit) * 2); text-align: right; }
	}
}

UL { padding: 0; margin: 0; list-style: none; }

UL:not([class]) {
	padding: 0; margin: 1em 0 0 0;

	LI {
		display: block; padding: 0 0 0 var(--gridunit); margin: 0; position: relative; page-break-inside: avoid;

		+ LI { margin-top: var(--gridunit-tiny); }

		&:before { content: ""; left: var(--gridunit-small); top: 10px; width: 6px; height: 6px; border-radius: 50%; background: currentColor; position: absolute; }
	}
}

MARK { display: inline; color: inherit; }
