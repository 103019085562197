@import 'mixins';

/* stylelint-disable rule-empty-line-before  */

HTML, BODY { font-family: var(--font-base), Arial, Helvetica, sans-serif; font-size: 16px; line-height: 24px; }
BODY { -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

H1, H2, H3, H4, H5, H6 {
  display: block; line-height: normal; margin: 1.2em 0 0 0; flex: 0 0 auto !important; font-family: var(--font-base), Arial, Helvetica, sans-serif; font-weight: var(--medium); text-wrap: balance;
  page-break-inside: avoid; page-break-after: avoid;
}

H1 {
  font-size: 60px; line-height: 78px; @include block-margin;

  @media print { margin-top: 0 !important; }
}

H2 { font-size: 40px; line-height: 52px; }

H3 { font-size: 32px; line-height: 40px; }

H4 { font-size: 18px; line-height: 1.1; text-transform: uppercase; font-weight: var(--medium); border-left: 4px solid var(--color-primary-400); padding-left: 8px; }

@include mobile {
  H1 { font-size: 40px; line-height: normal; }
  H2 { font-size: 32px; line-height: normal; }
  H3 { font-size: 20px; line-height: normal; }
}
