@import 'reset';
@import 'typography';
@import 'basics';
@import 'helpers';
@import 'content';
@import 'didomi';

@property --w-raw {
  syntax: '<length>';
  inherits: true;
  initial-value: 100vw;
}
@property --h-raw {
  syntax: '<length>';
  inherits: true;
  initial-value: 100vh;
}

:root {
  --viewport-width: tan(atan2(var(--w-raw), 1px));
  --viewport-height: tan(atan2(var(--h-raw), 1px));

  // Theme marker
  --theme: 'default';

  // Base colors
  --color-white: #ffffff;
  --color-black: #000000;

  // Primary color
  --color-primary-50: #E6F0FE;
  --color-primary-100: #CEE1FD;
  --color-primary-200: #9DC4FB;
  --color-primary-300: #6CA7F9;
  --color-primary-400: #3B89F6;
  --color-primary-500: #0B6CF4;
  --color-primary-600: #0856C3;
  --color-primary-700: #1E4691;
  --color-primary-800: #042B62;
  --color-primary-850: #032049;
  --color-primary-900: #021631;

  // Neutral color
  --color-neutral-50: #F2F2F3;
  --color-neutral-100: #E4E5E7;
  --color-neutral-200: #C9CBCF;
  --color-neutral-300: #AEB1B7;
  --color-neutral-400: #93979F;
  --color-neutral-500: #787D87;
  --color-neutral-600: #60646C;
  --color-neutral-700: #484B51;
  --color-neutral-800: #303236;
  --color-neutral-900: #18191B;

  // Alert color
  --color-alert-200: #FBA39D;
  --color-alert-400: #F73C3B;
  --color-alert-500: #D61E1F;
  --color-alert-600: #C41408;
  --color-alert-800: #620A04;

  // Success color
  --color-success-200: #9DFBA3;
  --color-success-400: #3BF747;
  --color-success-600: #08C414;
  --color-success-800: #04620A;

  // Warning color
  --color-warning-200: #F5FB9D;
  --color-warning-400: #EBF73B;
  --color-warning-600: #B8C408;
  --color-warning-800: #5C6204;

  // Other colors
  --color-focus: var(--color-primary-500);
  --color-danger: var(--color-alert-400);
  --color-success: var(--color-success-600);
  --color-warning: #FBE429;

  // Color mapper
  --site-bg: var(--color-white);
  --site-bg-original: var(--color-white);
  --color-primary: var(--color-primary-500);
  --color-secondary: var(--color-primary-900);
  --color-text: var(--color-primary-900);

  --header-bg: var(--color-primary-850);
  --header-color: var(--color-white);
  --header-secondary: var(--color-secondary);
  --footer-bg: var(--color-primary-850);
  --footer-color: var(--color-white);
  --backdrop-bg: var(--color-primary-850);
  --button-bg: var(--color-primary);
  --button-color: var(--color-white);

  --badge-default-bg: var(--color-primary-600);
  --badge-default-color: var(--color-white);
  --badge-discrete-bg: var(--color-neutral-200);
  --badge-discrete-color: var(--color-primary-900);

  --color-link: var(--color-primary-500);
  --color-link-hover: var(--color-primary-700);
  --color-highlight: var(--color-primary-500);

  // Size units
  --gridunit: 32px;
  --gridunit-tiny: 8px;
  --gridunit-small: 16px;
  --gridunit-medium: 24px;
  --gridunit-large: 72px;
  --gridunit-larger: 120px;
  --gridunit-largest: 144px;
  --content-maxwidth: 1216px;
  --radius-small: 4px;
  --radius-default: 8px;
  --radius-large: 16px;
  --scrollbar-width: 0px;

  // Animations
  --ui-speed: 250ms;
  --ui-easing: cubic-bezier(0.42, 0, 0.58, 1);

  // Typography
  --bold: 500;
  --medium: 500;
}

HTML, BODY {
  background-color: var(--site-bg);
  padding: 0;
  margin: 0;
}

#__next {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  position: relative;
}
