@import "mixins";

#didomi-host.didomi-host {
  font-size: rem(16); line-height: 1.35;

  @include mobile {
    --gridunit: 16px;
  }

  P { margin: 0 0 1em 0; }

  A:not(.didomi-no-link-style) { color: var(--color-link) !important; }

  UL {
    LI {
      display: block; margin: 0 !important;
    }
  }

  *:focus { outline: none !important; }

  .didomi-popup {
    &-backdrop {
      background-color: transparent; padding: var(--gridunit) !important; box-sizing: border-box;

      &:before { content: ""; background-color: var(--backdrop-bg); opacity: 0.8; inset: 0; position: fixed; z-index: -1; }
    }

    &-header { padding: var(--gridunit) !important; padding-bottom: 0 !important; font-family: var(--font-headings), sans-serif; font-size: rem(18) !important; }

    &-body {
      padding: var(--gridunit) !important;

      > *:last-child { margin-bottom: 0 !important; }
    }

    &-footer {
      padding: var(--gridunit); padding-top: 0 !important; height: auto !important;

      @include mobile { flex-direction: column; }
    }

    &-container { border: none; border-radius: 0; padding: 0; }

    &__exterior-border { border: none; border-radius: 0; padding: 0; }

    &-notice-with-data-processing.didomi-popup-notice { padding: var(--gridunit) !important; }
  }

  .didomi-exterior-border { padding: 0 !important; }

  .didomi-buttons {
    gap: var(--gridunit-small); flex-wrap: wrap;

    @include mobile { flex-direction: column; }
  }

  .didomi-button {
    border-width: 0 !important; border-radius: var(--radius-default) !important; height: rem(40) !important; font-weight: 400 !important; min-width: 100px !important;
    outline: none !important; margin: 0 !important; white-space: nowrap !important; box-sizing: border-box;

    &.didomi-button-standard { box-shadow: inset 0 0 0 1px currentColor; }

    &.standard-button {}

    > SPAN { white-space: nowrap !important; display: block; }

    @include mobile { width: 100%; }
  }

  .didomi-components-button { margin: 0 !important; }

  .didomi-screen-xsmall .didomi-popup-notice-buttons .didomi-components-button { margin: 0 !important; }

  #didomi-vendors-count .didomi-popup-header { background-color: var(--color-neutral-100) !important; padding: var(--gridunit) !important; }

  .vendors-list-labels { margin: 1em 0 0 0 !important; }

  .didomi-consent-popup-view-vendors-list-link {
    box-shadow: inset 0 0 0 1px var(--color-primary-900) !important; outline: none; font-weight: 400 !important; font-size: rem(14) !important; color: var(--color-primary-900) !important;
    padding: 0 var(--gridunit-small) !important; border: none !important; width: auto !important; line-height: var(--gridunit-medium);
  }

  .didomi-components-radio__option {
    box-shadow: inset 0 0 0 1px var(--color-primary-900); outline: none; font-weight: 400; font-size: rem(14) !important; color: var(--color-primary-900);
    padding: 0 var(--gridunit-small) !important; border: none !important; width: auto !important; line-height: var(--gridunit-medium);

    svg { display: none; }

    &#didomi-radio-option-agree-to-all,
    &#didomi-radio-option-disagree-to-all { font-size: rem(14) !important; }
  }

  .didomi-notice-data-processing-container {
    font-size: inherit !important; font-weight: 500 !important; line-height: inherit !important; text-align: left !important;

    P { font-size: inherit !important; font-weight: 500 !important; line-height: inherit !important; }

    .didomi-notice-data-processing-title { color: var(--color-neutral-800) !important; font-weight: 700 !important; }

    .didomi-notice-data-processing-list { color: var(--color-neutral-800) !important; font-weight: 400 !important; }
  }

  .didomi-consent-popup-category {
    border-top: 1px solid var(--color-neutral-400); border-bottom: 1px solid var(--color-neutral-400);

    + .didomi-consent-popup-category { margin-top: 0; border-top: none; }
  }

  .didomi-consent-popup-vendor {
    border-top: 1px solid var(--color-neutral-400); border-bottom: 1px solid var(--color-neutral-400); margin: 0 !important;

    + .didomi-consent-popup-vendor { margin-top: 0; border-top: none; }
  }

  .didomi-first-letter-vendors-list { font-size: rem(21) !important; margin: 1em 0; font-weight: 700; }

  .didomi-storage-info { border-top: 1px solid var(--color-neutral-400); border-bottom: 1px solid var(--color-neutral-400); margin-bottom: 1em; }

  .didomi-components-accordion {
    &-label-container { padding: var(--gridunit-small) 0; text-decoration: none !important; }

    .label-click {
      font-family: var(--font-headings), sans-serif; display: flex; align-items: flex-start; gap: var(--gridunit-small); line-height: 1.2 !important; text-align: left !important;
      flex: 1 1 auto !important;

      > .trigger-icon + SPAN {
        display: flex; flex-direction: row; flex: 1 1 auto; align-items: center; flex-wrap: wrap;

        @include mobile { gap: var(--gridunit-tiny); align-items: center; }
      }
    }

    .didomi-vendors-details-title { flex: 1 1 auto; }

    .didomi-content { text-align: left !important; }
  }

  .didomi-consent-popup-data-processing__essential_purpose { color: var(--color-danger) !important; margin: 0 0 0 var(--gridunit) !important; flex: 0 0 auto !important; }

  .didomi-consent-popup-data-processing__essential_purpose_explanation { color: var(--color-danger) !important; }

  .didomi-consent-popup-data-processing-illustrations { background-color: var(--color-neutral-100) !important; font-size: rem(12) !important; border: none !important; line-height: 1.35 !important; }

  .didomi-consent-popup-data-processing__description { color: inherit !important; line-height: inherit !important; }

  .didomi-consent-popup-categories {
    margin: 1em 0;

    .didomi-consent-popup-data-processing { border-top: 1px solid var(--color-neutral-300) !important; margin: 0 !important; }
  }

  .didomi-vendors-disclaimer,
  .didomi-vendors-iab-disclaimer { margin: 1em 0 0 0 !important; }

  .didomi-consent-popup-vendor__description > DIV { margin: 0; }

  .didomi-retention-time,
  .didomi-storage-info__time-label { background-color: var(--color-neutral-200) !important; }

  .didomi-vendors-iab-label {
    background-color: var(--color-neutral-200) !important; padding: 4px 6px; border-radius: var(--radius-small); vertical-align: 15% !important;
    white-space: nowrap;
  }

  .didomi-consent-popup-body__section { border: none; margin: var(--gridunit) 0; }

  .didomi-consent-popup-body_vendors-list { border: solid var(--color-neutral-900) !important; border-width: 0 1px 1px 1px !important; padding: var(--gridunit-medium) !important; }

  .didomi-consent-popup-category__name { margin-bottom: 0 !important; }

  .didomi-consent-popup-container-click-all {
    border: none !important; background-color: transparent !important; padding: 0 !important;
    align-items: center;

    &.didomi-consent-popup-vendor { border: 1px solid var(--color-neutral-900) !important; background-color: var(--color-neutral-100) !important; padding: var(--gridunit-small) var(--gridunit-medium) !important; }
  }

  .didomi-consent-popup-body__title,
  .didomi-consent-popup-vendor__name {
    font-family: var(--font-headings), sans-serif !important; font-size: rem(16) !important; margin: 0 !important;
    text-transform: none !important; color: inherit !important;
  }

  .didomi-consent-popup-body__title { font-size: rem(21) !important; }

  @include mobile {
    .didomi-components-accordion-label-container { flex-direction: column; gap: var(--gridunit-tiny); align-items: stretch !important; }

    .didomi-consent-popup-data-processing { padding-bottom: var(--gridunit) !important; }

    .didomi-consent-popup-data-processing__buttons { margin-left: calc(15px + var(--gridunit)) !important; }

    .didomi-consent-popup-data-processing__purpose_actions { flex-wrap: wrap; gap: var(--gridunit-tiny); }

    .didomi-consent-popup-container-click-all { gap: var(--gridunit-tiny); }

    .didomi-consent-popup-purpose__buttons { margin: 0 !important; }

    .didomi-consent-popup-vendor { padding-bottom: var(--gridunit); }
  }
}
